canvas {
      position: fixed;
      top: 0;
}

.btn-close {
      position: absolute;
      top: 20px;
      right: 16px;
      z-index: 9999;
}

.breadcrumb-item.active {
      color: #e1e1e1;
}

.spinner-border {
      --bs-spinner-width: 1.2rem;
      --bs-spinner-height: 1.2rem;
      border-width: 2px;
      margin-left: 0.5rem;
}

.form-select:focus {
      box-shadow: none;
}

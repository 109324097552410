body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
}

code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
}
:root {
      --primary__color: #d90117;
      --white__color: #fff;
}
.modal-header {
      background: var(--primary__color);
      color: var(--white__color);
}
.custom-button {
      background: var(--primary__color);
      color: var(--white__color);
      padding: 10px 30px;
      border-radius: 10px 0px 10px 0px;
}

.custom-button svg {
      width: 20px;
      height: 20px;
      fill: white;
      margin-right: 5px;
}

.custom-button.btn:hover {
      background: #9e0010;
      color: var(--white__color);
}
.btn-close {
      --bs-btn-close-color: var(--white__color);
}
.form-control:focus {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border-color: var(--primary__color);
      outline: 0;
      box-shadow: none;
}
